import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { navigate } from 'gatsby-link';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../../styles/index';
import { SettingsContext } from '../../../contexts';
import DelayedLink from '../../../utils/DelayedLink';
import Icons from '../../../utils/Icons';
import LinkTool from '../../shared/LinkTool';
import {
  useGlobalDictionaryQuery,
  useLocale,
  useLockScroll,
  useGlobalLayoutQuery,
} from '../../../hooks/index';
import { useLang } from '../../../hooks/layout/useLang';

interface Props {
  menuData: [];
  isUserLogged: boolean;
}

export default function MenuMobileMain(props: Props) {
  const currLocale = useLocale();
  const allLocaleSlugs = useLang();
  const langs = Object.keys(allLocaleSlugs);
  const layout = useGlobalLayoutQuery();
  const { menuData, isUserLogged } = props;

  const {
    isMenuOpen,
    setMenuOpen,
    resetFilters,
    renderTopBar,
  }: {
    isMenuOpen: any;
    setMenuOpen: any;
    renderTopBar: any;
    resetFilters: () => void;
  } = useContext(SettingsContext);

  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState([]);

  useLockScroll(isMenuOpen);

  const dictionary = useGlobalDictionaryQuery();

  const menuSpring = useSpring({
    transform: isMenuOpen ? 'translateX(0%)' : 'translateX(-100%)',
    config: {
      tension: 130,
      friction: 21,
    },
    delay: 200,
  });

  const setItemsWithSubmenu = (submenu, typename) => {
    if (typename === 'ContentfulComponentMenuGroup') {
      setVisible(false);
      setTimeout(() => {
        handleScroll();
      }, 620);
      setTimeout(
        () => {
          setItems(submenu);
          setVisible(true);
        },
        visible ? 250 : 620
      );
    }
  };

  const handleCloseMenu = text => {
    if (items === menuData) {
      setMenuOpen(false);
    } else {
      setVisible(false);
      setTimeout(
        () => {
          setItems(menuData);
          setVisible(true);
        },
        visible ? 250 : 620
      );
    }
  };

  const handleCloseMenuText = () => {
    if (items === menuData) {
      return dictionary.close;
    }
    return dictionary.back;
  };

  const handleLanguageSelection = e => {
    resetFilters();
    const newLang = e;
    const newUrl = `/${newLang}/${allLocaleSlugs[newLang]}`;
    if (allLocaleSlugs[newLang] === 'account') {
      navigate(`/${newLang}/`);
    } else {
      navigate(newUrl);
    }
    setTimeout(() => {
      setMenuOpen(false);
    }, 500);
  };

  useEffect(() => {
    // if(isMenuOpen)setTimeout(()=>setVisible(true), 1700)
    if (!isMenuOpen) {
      setVisible(false);
    }
    if (isMenuOpen && menuData) {
      setVisible(false);
      setTimeout(
        () => {
          setItems(menuData);
          setVisible(true);
        },
        visible ? 550 : 620
      );
    }
  }, [isMenuOpen, menuData]);

  const containerRef = useRef();
  const scrollRef = useRef();
  const [opacity, setOpacity] = useState(1);
  const [positionMenu, setPositionMenu] = useState('70px');

  const handleScroll = () => {
    const scrollRange = scrollRef.current.clientHeight - containerRef.current.clientHeight;
    const difference = containerRef.current.scrollTop;
    // console.log(difference, scrollRange);
    if (scrollRange <= 1 && difference <= 1) {
      setOpacity(0);
    } else {
      const mappedValue = (difference * 100) / scrollRange;
      // eg. range is 44, scrollTop ranges from 0 to 44, map those value in a 100% scale, use that scale to reduce intensity on opacity.
      // console.log(mappedValue);
      setOpacity(1 - mappedValue / 100);
    }
  };
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      handleScroll();
      containerRef.current.addEventListener('scroll', handleScroll, { passive: true });
      return () => containerRef.current.removeEventListener('scroll', handleScroll);
    }
  }, [containerRef, scrollRef]);

  useEffect(() => {
    if (renderTopBar) {
      setPositionMenu('70px');
    } else {
      setPositionMenu('0');
    }
  }, [renderTopBar]);

  return (
    <Wrapper
      style={{
        ...menuSpring,
        backgroundColor: layout.colorBackgroundSidebar
          ? layout.colorBackgroundSidebar
          : colors.black,
      }}
    >
      <MenuHeader style={{ marginTop: positionMenu }}>
        <LogoWrapper>
          <LinkTool to="/" onClick={() => setMenuOpen(false)}>
            <Icons name="logoMainHeader" data={layout.colorMenuInSidebar} />
          </LinkTool>
        </LogoWrapper>
        <CloseMenuButton
          style={{ color: layout.colorMenuInSidebar }}
          aria-label="close menu"
          type="button"
          onClick={() => handleCloseMenu()}
        >
          {handleCloseMenuText()}
        </CloseMenuButton>
      </MenuHeader>
      <ScrollOuter>
        {/* <ScrollFadeOverlay opacity={opacity} /> */}
        <ScrollInner>
          <RelativeWrapper ref={containerRef}>
            <ItemsWrapper ref={scrollRef}>
              {items &&
                items.map(({ title, totalProd, target, targetData, menus, __typename }, index) => {
                  let color = 'default';
                  if (items[0].menus) {
                    const similiarName = items[0].menus.filter(item => item.title === title);
                    if (similiarName?.length > 0) {
                      color = similiarName[0]?.color;
                    }
                  } else if (items?.length > 0) {
                    const similiarName = items.filter(item => item.title === title);
                    if (similiarName?.length > 0) {
                      color = similiarName[0]?.color;
                    }
                  }

                  return (
                    <MenuChild
                      index={index}
                      title={title || target.title}
                      key={index}
                      color={color}
                      visible={visible}
                      setVisible={setVisible}
                      target={target}
                      totalProd={totalProd}
                      targetData={targetData}
                      items={menus}
                      setItemsWithSubmenu={setItemsWithSubmenu}
                      typename={__typename}
                    />
                  );
                })}
            </ItemsWrapper>
          </RelativeWrapper>
        </ScrollInner>
      </ScrollOuter>
      <MenuFooter>
        <CartButton
          aria-label="Account"
          type="button"
          style={{ color: layout.colorMenuInSidebar }}
          onClick={() => {
            navigate(isUserLogged ? `/${currLocale}/account` : `/${currLocale}/account/login`);
            setTimeout(() => {
              setMenuOpen(false);
            }, 250);
          }}
        >
          {isUserLogged ? 'My account' : 'Login'}
        </CartButton>

        <LanguageSelect
          name="sizes"
          id="sizes"
          value={currLocale}
          onChange={e => handleLanguageSelection(e.target.value)}
          style={{ color: layout.colorMenuInSidebar }}
        >
          {langs.map(lang => (
            <option value={lang} key={lang}>
              {lang}
            </option>
          ))}
        </LanguageSelect>
      </MenuFooter>
    </Wrapper>
  );
}

const LanguageSelect = styled.select`
  color: ${colors.black};
  width: auto;
  top: 0;
  right: ${mobileVW(30)};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: white;
  font-size: ${mobileVW(18)};
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  text-transform: capitalize;

  option {
    text-align: right;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(160)};
    margin-left: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
    cursor: pointer;
  }
`;

const Wrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.satan};
  flex: 2 2;
  display: flex;
  flex-direction: column;
`;

const RelativeWrapper = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuHeader = styled.div`
  height: ${mobileVW(70)};
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${desktopVW(2)} solid ${colors.subtleGrey};
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
  }
`;

const LogoWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const CloseMenuButton = styled.button`
  font-family: 'messinaMono';
  font-size: ${mobileVW(16)};
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  height: 100%;
  width: auto;
  padding: 0 ${mobileVW(20)};
`;

const ScrollOuter = styled.div`
  position: relative;
  z-index: 1;
  overflow-y: scroll;
  width: 100%;
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
  overscroll-behavior: contain;
`;

const ScrollFadeOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  background: ${({ opacity }) =>
    `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(38,37,37,0) 67%, rgba(38,37,37,${opacity}) 97%)`};
`;

const ScrollInner = styled.div`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuFooter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${mobileVW(25)} ${mobileVW(20)};
  border-top: ${mobileVW(1)} solid ${colors.subtleGrey};
`;

const CartButton = styled.button`
  z-index: ${zIndex.surface};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  color: ${colors.white};
  font-size: ${mobileVW(16)};
  letter-spacing: -0.05em;
  padding: 0 ${mobileVW(34)} 0 0;
`;

// ----------------------------- HEADER COMPONENT

const MenuChild = ({
  index,
  title,
  visible,
  target,
  targetData,
  items,
  color,
  setItemsWithSubmenu,
  typename,
  setVisible,
}) => {
  const currLocale = useLocale();
  const layout = useGlobalLayoutQuery();
  const {
    setMenuOpen,
    setCategoryFilter,
  }: {
    setMenuOpen: any;
    setCategoryFilter: any;
  } = useContext(SettingsContext);

  const delay = index => {
    if (!visible) return 0;
    if (visible) return index * 50;
  };

  const leftMenuChildSpring = useSpring({
    to: { opacity: visible ? 1 : 0 },
    config: {
      tension: 250,
      friction: 25,
    },
    delay: delay(index),
  });

  const handleMenuChildclick = (type, target, targetData) => {
    if (type === 'ContentfulComponentMenuGroup') {
      setItemsWithSubmenu(items, typename);
    } else if (targetData) {
      if (targetData.charAt(0) === '%') {
        if (targetData.substring(1) === ' ' || targetData === '%') {
          setCategoryFilter([]);
        } else {
          setCategoryFilter([targetData.substring(1)]);
        }
      }
      navigate(
        targetData !== '%'
          ? `/${currLocale}/${encodeURIComponent(
              targetData
                .replace('%', '')
                .replace('&', '-and-')
                .replace(/ /g, '')
                .toLowerCase()
            )}`
          : `/${currLocale}/${target.slug}/`
      );
      setTimeout(() => {
        setMenuOpen(false);
      }, 400);
    } else {
      navigate(`/${currLocale}/${target.slug}/`);
      setTimeout(() => {
        setMenuOpen(false);
      }, 400);
    }
  };

  return (
    <LeftMenuChildWrapper onClick={() => handleMenuChildclick(typename, target, targetData)}>
      <Text style={{ ...leftMenuChildSpring }}>
        {color != 'default' && color != undefined ? (
          <span style={{ color }}> {title}</span>
        ) : (
          <span style={{ color: layout.colorMenuInSidebar }}> {title}</span>
        )}
        {typename === 'ContentfulComponentMenuGroup' && (
          <IconWrapper>
            <Icons name="arrowRight" color={layout.colorMenuInSidebar} />
          </IconWrapper>
        )}
      </Text>
    </LeftMenuChildWrapper>
  );
};

const LeftMenuChildWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: ${mobileVW(50)};
  text-align: left;
  //padding-left: ${desktopVW(60)};
  overflow: hidden;
  position: relative;

`;

const Text = styled(animated.div)`
  width: 100%;
  height: 100%;
  font-size: ${mobileVW(28)};
  font-family: 'messinaMono';
  //transform: translateY(-180%);
  opacity: 0;
  cursor: pointer;
  color: ${colors.white};
  //border-bottom: ${mobileVW(1)} solid ${colors.subtleGrey};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${mobileVW(20)};

`;

const IconWrapper = styled.div`
  position: absolute;
  right: ${mobileVW(20)};
  top: 50%;
  transform: translateY(-50%);

  ${desktopBreakpoint} {
  }
`;
