import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext, StoreContext } from '../../contexts';
import {
  useAddItemsToCart,
  useCartCount,
  useCartItems,
  useCheckoutUrl,
  useConvertCustomAttributes,
  useGetLineItem,
  useGlobalDictionaryQuery,
  useProtectionProducts,
  useRemoveItemFromCart,
  useRemoveItemsFromCart,
  useUpdateItem,
} from '../../hooks';
import { useLocale } from '../../hooks/layout/useLocale';
import { colors, desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import Icons from '../../utils/Icons';
import formatPrice from '../../utils/formatPrice';
import CartSkylt from './CartSkylt';

interface Props {
  allProductsTitleSlug: any;
}

export default function Cart(props: Props) {
  const {
    isCartOpen,
    setIsCartOpen,
    isCartLoading,
    setIsCartLoading,
    allProductShopify,
  }: {
    isCartOpen: any;
    setIsCartOpen: any;
    isCartLoading: any;
    setIsCartLoading: any;
    allProductShopify: any;
  } = useContext(SettingsContext);
  const { client, cart } = useContext(StoreContext);

  const dictionary = useGlobalDictionaryQuery();
  const protectionProducts = useProtectionProducts();
  const protectionProductIds =
    typeof protectionProducts === 'object'
      ? Object.keys(protectionProducts).map(key => protectionProducts[key].id)
      : [];

  const cartData = useCartItems();
  const [cartItems, setCartItems] = useState(false as any);
  const locale = useLocale();
  const checkoutUrl = useCheckoutUrl();

  const convertCustomAttributes = useConvertCustomAttributes();
  const getItem = useGetLineItem();
  const updateItem = useUpdateItem();
  const removeItem = useRemoveItemFromCart();
  const removeItems = useRemoveItemsFromCart();
  const addItems = useAddItemsToCart();
  const cartCount = useCartCount();
  const cartSpring = useSpring({
    transform: isCartOpen ? 'translateX(0%)' : 'translateX(100%)',
    config: {
      tension: 160,
      friction: 25,
    },
    delay: 200,
  });

  useEffect(() => {
    if (cartCount === 0) setTimeout(() => setIsCartOpen(false), 250);
  }, [cartCount]);

  const handleBackgroundclick = () => {
    if (isCartOpen) {
      setIsCartOpen(false);
    }
  };

  const handleItemDeleteClick = async (variantId, customAttributes) => {
    const lineItem = getItem(variantId, customAttributes);
    await removeItem(lineItem.id);
  };

  useEffect(() => {
    setCartItems(cartData);
  }, [cartData]);

  const getMaximalQuantity = (items: any = []) => {
    let dataNote: any = [];

    items.map((data: any) => {
      let getData = allProductShopify.filter(
        (item: any) => item.node.id === data.variant.product.id
      );
      getData = getData[0].node.variants.edges.filter(
        (item: any) => item.node.sku === data.variant.sku
      );
      dataNote.push({ sku: getData[0].node.sku, quantity: getData[0].node.quantityAvailable });
    });

    return dataNote;
  };

  async function createCheckoutWithNote(id: number | string, note: string): Promise<any> {
    try {
      // Create a new checkout
      const updateNote = client.checkout.updateAttributes(id, {
        note: note,
      });
      return updateNote;
    } catch (error) {
      console.error('Error creating checkout or adding note:', error);
      throw error; // Re-throw the error to ensure the Promise rejects
    }
  }

  const handleCheckoutClick = async () => {
    setIsCartLoading(true);

    let getIdCheckout: any = checkoutUrl?.split('checkouts/');
    getIdCheckout = `gid://shopify/Checkout/${getIdCheckout[1]}`;

    let note = getMaximalQuantity(cart?.lineItems);

    createCheckoutWithNote(getIdCheckout, JSON.stringify(note))
      .then(data => {
        onCheckout();
      })
      .catch(error => {
        console.error('failed to add note');
      });
    console.log('note', cart);
  };

  const onCheckout = async () => {
    if (typeof window !== 'object' || cartCount === 0) return;
    const isProtectionProduct = item => protectionProductIds.includes(item.variant.id);
    const findSkyltAttribute = attribute => attribute.key.includes('Extra protection');
    const doesItemHaveSkylt = item =>
      item.customAttributes.find(findSkyltAttribute)?.value === 'added';
    const oldProtectionProducts = cartItems.filter(isProtectionProduct).map(item => item.id);

    const newProtectionProducts = cartItems
      .filter(item => doesItemHaveSkylt(item) && !isProtectionProduct(item))
      .map(item => {
        const value = item.customAttributes
          .find(findSkyltAttribute)
          ?.key.split('-')[1]
          .trim();
        const variantId = protectionProducts[value]?.id;
        const quantity = item.quantity;

        return {
          variantId,
          quantity,
          customAttributes: [],
        };
      });

    try {
      let checkout = checkoutUrl;

      if (oldProtectionProducts.length) await removeItems(oldProtectionProducts);
      if (newProtectionProducts.length) {
        const { webUrl } = await addItems(newProtectionProducts);

        checkout = webUrl;
      }

      window.location.href = `${checkout}&locale=${locale}`;

      setIsCartLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const cartBackgroundSpring = useSpring({
    pointerEvents: isCartOpen ? 'auto' : 'none',
    cursor: isCartOpen ? 'pointer' : 'auto',
    opacity: isCartOpen ? 1 : 0,

    config: {
      tension: 200,
      friction: 20,
    },
  });

  const onUpdateProduct = async (
    id: number | string,
    quantity: number,
    status: 'plus' | 'minus',
    properties: any
  ) => {
    let currentQuantity: number = 0;

    if (status === 'plus') {
      currentQuantity = quantity + 1;
    } else {
      currentQuantity = quantity - 1;
    }
    await updateItem(id, currentQuantity, properties);
    setIsCartLoading(false);
  };

  return (
    <>
      <Background style={{ ...cartBackgroundSpring }} onClick={() => handleBackgroundclick()} />
      <Wrapper style={{ ...cartSpring }}>
        <CartHeader>
          <CartTitle>
            {dictionary?.cartText}
            <CartTitleCount>{useCartCount()}</CartTitleCount>
          </CartTitle>
          <CartButtonClose
            aria-label="close cart"
            type="button"
            onClick={() => setIsCartOpen(false)}
          >
            {dictionary?.closeCartText}
          </CartButtonClose>
        </CartHeader>
        <ScrollOuter isLoading={isCartLoading}>
          <ScrollInner>
            <CartBody>
              {cartItems &&
                cartItems
                  .filter((item: any) => !protectionProductIds.includes(item.variant.id))
                  .map((lineItem, index) => {
                    const { title, variant, quantity, customAttributes } = lineItem;
                    const findSkyltAttribute = attribute =>
                      attribute.key.includes('Extra protection');
                    const skyltOptionIndex = customAttributes.findIndex(findSkyltAttribute);
                    const itemHasSkyltOption = skyltOptionIndex !== -1;
                    const itemHasSkyltAdded =
                      customAttributes.find(findSkyltAttribute)?.value === 'added';
                    const handleSkyltClick = async () => {
                      setIsCartLoading(true);
                      const newCustomAttributes = convertCustomAttributes(customAttributes);
                      newCustomAttributes[skyltOptionIndex].value = itemHasSkyltAdded
                        ? 'not added'
                        : 'added';
                      await updateItem(variant.id, quantity, customAttributes, newCustomAttributes);
                      setIsCartLoading(false);
                    };

                    return (
                      <CartItemWrapper key={title + index}>
                        <CartItemImg>
                          <img src={variant.image.src} />
                        </CartItemImg>

                        <CartItemContent>
                          <CartItemTitle>
                            <p>{lineItem.title}</p>
                            <p className="cartItemTitle__paddingTop">{variant.title}</p>
                            {itemHasSkyltOption && (
                              <CartSkylt lineItem={lineItem} handleSkyltClick={handleSkyltClick} />
                            )}
                          </CartItemTitle>
                          <CartItemPrice>
                            {formatPrice(variant.price.amount * quantity, '€')}
                          </CartItemPrice>
                          <CartItemQuantity>
                            <p className="cartItemTitle__quantityTitle">
                              {dictionary?.cartQuantityText}
                            </p>
                            <CartItemQuantityGrid>
                              <div
                                className="CartItem__quantityItem cartItem__gridLeft"
                                onClick={() => {
                                  onUpdateProduct(
                                    variant.id,
                                    quantity,
                                    'minus',
                                    customAttributes,
                                    lineItem
                                  );
                                }}
                              >
                                -
                              </div>
                              <div className="CartItem__quantityItem">{quantity}</div>
                              <div
                                className="CartItem__quantityItem cartItem__gridRight"
                                onClick={() => {
                                  onUpdateProduct(
                                    variant.id,
                                    quantity,
                                    'plus',
                                    customAttributes,
                                    lineItem
                                  );
                                }}
                              >
                                +
                              </div>
                            </CartItemQuantityGrid>
                          </CartItemQuantity>
                          <CartItemDelete
                            type="button"
                            aria-label={`delete ${title} from cart`}
                            onClick={async () => {
                              setIsCartLoading(true);
                              await handleItemDeleteClick(variant.id, customAttributes);
                              setIsCartLoading(false);
                            }}
                          >
                            {dictionary?.deleteFromCartText}
                          </CartItemDelete>
                        </CartItemContent>
                      </CartItemWrapper>
                    );
                    // }
                  })}
            </CartBody>
          </ScrollInner>
        </ScrollOuter>
        <CartFooterWrapper>
          <CartFooter>
            <CheckoutButton
              type="button"
              aria-label="go to checkout"
              disabled={isCartLoading}
              onClick={handleCheckoutClick}
            >
              {isCartLoading ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                dictionary?.goToCheckoutText
              )}
            </CheckoutButton>
            <CartFooterText>
              <p>{dictionary?.shippingMessageCart}</p>
              <PaymentLogosWrapper>
                <PaymentLogos>
                  <BoxIcon>
                    <Icons name="ideal" />
                  </BoxIcon>
                  <BoxIcon>
                    <Icons name="paypal" />
                  </BoxIcon>
                  <BoxIcon>
                    <Icons name="mastercard" />
                  </BoxIcon>
                </PaymentLogos>
                <PaymentLogos>
                  <BoxIcon>
                    <Icons name="visa" />
                  </BoxIcon>
                  <BoxIcon>
                    <Icons name="amEx" />
                  </BoxIcon>
                  <BoxIcon>
                    <ImageIcon
                      src={
                        'https://images.ctfassets.net/hhqcfrz88uh8/3pUbHATY9PrNrTpWA6Av0m/2f02a198a9bcbb882fcd16232ddb8c8f/Klarna-Logo.wine__1_.png?w=800&h=183&q=50&fm=webp'
                      }
                      alt={dictionary?.iconKlarna?.title}
                    />
                  </BoxIcon>
                </PaymentLogos>
              </PaymentLogosWrapper>
            </CartFooterText>
          </CartFooter>
        </CartFooterWrapper>
      </Wrapper>
    </>
  );
}

const Background = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: ${zIndex.header + 2};
  background-color: rgba(0, 0, 0, 0.4);
`;

const BoxIcon = styled.div`
  margin-right: 5px;
  display: flex;
`;

const ImageIcon = styled.img`
  width: 80px;
`;

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: ${zIndex.header + 10};
  transform: translateX(100%);
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${desktopBreakpoint} {
    width: ${desktopVW(600)};
  }
`;

const CartHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${mobileVW(100)};
  padding: 0 ${mobileVW(30)};
  border-bottom: ${mobileVW(2)} solid rgba(0, 0, 0, 0.1);

  ${desktopBreakpoint} {
    height: ${desktopVW(120)};
    border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);
    padding: ${desktopVW(5)} ${desktopVW(62)} 0 ${desktopVW(56)};
  }
`;

const CartTitle = styled.p`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.black};
  position: relative;
  font-size: ${mobileVW(18)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(25)};
  }
`;

const CartTitleCount = styled.span`
  position: absolute;
  color: ${colors.lightGrey};
  top: 0;
  right: ${mobileVW(-10)};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(13)};
    top: 0;
    right: ${desktopVW(-10)};
  }
`;

const CartButtonClose = styled.button`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  text-decoration: underline;
  font-size: ${mobileVW(15)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const CartBody = styled.div`
  width: 100%;
  height: auto;
  overflow: scroll;
  overscroll-behavior: contain;
  padding: 0 ${mobileVW(20)};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(60)};
    scrollbar-width: none;
  }
`;

const CartItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${mobileVW(180)};
  border-bottom: ${mobileVW(2)} solid rgba(0, 0, 0, 0.1);
  padding: ${mobileVW(26)} 0;

  ${desktopBreakpoint} {
    height: ${desktopVW(210)};
    border-bottom: ${desktopVW(1)} solid rgba(0, 0, 0, 0.1);
    padding: ${desktopVW(26)} 0;
  }
`;

const CartItemImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${mobileVW(128)};
  width: ${mobileVW(100)};
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(120)};
    height: 100%;
  }
`;

const CartItemContent = styled.div`
  width: 75%;
  height: 100%;
  position: relative;

  ${desktopBreakpoint} {
  }
`;

const CartItemTitle = styled.div`
  position: absolute;
  top: 0;
  left: ${mobileVW(15)};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};
  display: flex;
  flex-direction: column;
  font-size: ${mobileVW(12)};
  width: 80%;

  ${desktopBreakpoint} {
    width: 72%;
  }

  .cartItemTitle__paddingTop {
    color: ${colors.subtleGrey};
    padding-top: ${mobileVW(7)};
    ${desktopBreakpoint} {
      padding-top: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
    left: ${desktopVW(24)};
  }
`;

const CartItemPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
  }
`;

const CartItemQuantity = styled.div`
  position: absolute;
  bottom: 0;
  left: ${mobileVW(15)};
  display: flex;
  flex-direction: row;
  height: ${mobileVW(20)};

  .cartItemTitle__quantityTitle {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    height: 100%;
    text-align: center;
    color: ${colors.subtleGrey};
    font-size: ${mobileVW(12)};
    padding: ${mobileVW(2)} ${mobileVW(10)} 0 0;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(15)};
      padding: ${desktopVW(3)} ${desktopVW(15)} 0 0;
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(27)};
    left: ${desktopVW(24)};
  }
`;

const CartItemQuantityGrid = styled.div`
  display: grid;
  color: ${colors.subtleGrey};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  user-select: none;
  grid-template-columns: 30% 40% 30%;
  width: ${mobileVW(70)};
  border: ${mobileVW(1)} solid ${colors.lightGreyButLighter};

  .CartItem__quantityItem {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${mobileVW(12)};
    color: ${colors.thatSpecificColorThatTheDesignerUsedJustInTwoP};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(11)};
    }
  }

  .cartItem__gridLeft {
    border-right: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  .cartItem__gridRight {
    border-left: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    cursor: pointer;
  }

  ${desktopBreakpoint} {
    border: ${desktopVW(2)} solid ${colors.lightGreyButLighter};
    width: ${desktopVW(83)};
  }
`;

const CartItemDelete = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
  }
`;

const ScrollOuter = styled.div`
  position: relative;
  z-index: 1;
  overflow-y: scroll;
  width: 100%;
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  scrollbar-width: none;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollInner = styled.div`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CartFooterWrapper = styled.div`
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(60)};
  }
`;

const CartFooter = styled.div`
  width: 100%;
  padding: ${mobileVW(20)} 0 ${mobileVW(20)} 0;
  ${desktopBreakpoint} {
    padding: ${desktopVW(50)} 0 ${desktopVW(19)} 0;
  }
`;

const CheckoutButton = styled.button`
  width: 100%;
  background-color: ${colors.black};
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  color: ${colors.white};
  height: ${desktopVW(240)};
  font-size: ${mobileVW(15)};

  a {
    width: 100%;
    height: 100%;
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(75)};
    font-size: ${desktopVW(14)};
  }

  // Ring loader animation
  .lds-ring {
    display: inline-block;
    position: relative;
    width: ${mobileVW(38)};
    height: ${mobileVW(38)};
    ${desktopBreakpoint} {
      width: ${desktopVW(42)};
      height: ${desktopVW(42)};
    }
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${mobileVW(30)};
    height: ${mobileVW(30)};
    margin: ${mobileVW(4)};
    border: ${mobileVW(4)} solid #000000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
    ${desktopBreakpoint} {
      width: ${desktopVW(36)};
      height: ${desktopVW(36)};
      margin: ${desktopVW(4)};
      border: ${desktopVW(4)} solid #000000;
    }
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: ${colors.lightGreyButLighter};
    .lds-ring div {
      border-color: #c0c0c0 transparent transparent transparent;
    }
  }
`;

const CartFooterText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    width: 55%;
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    line-height: 155.3%;
    font-size: ${mobileVW(14)};
    padding-top: ${mobileVW(20)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(12)};
      padding-top: ${desktopVW(18)};
      width: 37%;
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(80)};
  }
`;

const PaymentLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: ${mobileVW(5)};
  height: ${mobileVW(25)};
  align-items: center;

  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(5)};
    height: ${desktopVW(25)};
  }
`;

const PaymentLogosWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //padding: ${mobileVW(30)} 0;
  margin-top: ${mobileVW(29)};

  ${desktopBreakpoint}{
    width: ${desktopVW(100)};
    height: ${desktopVW(50)};
    margin-top: ${desktopVW(24)};
  }
`;
