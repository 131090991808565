module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"D-Bodhi","short_name":"D-Bodhi","start_url":"/","background_color":"#F2EFEC","display":"standalone","theme_color":"#F2EFEC","icon":"static/FaviconDB.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38a574bb4b831d3941a226be8fa095a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["messina, messinaMono, messinaMonoLight"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KGJH6PZ","includeInDevelopment":true,"timeout":"1000"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
