import React, { useEffect, useState } from 'react';
import ShopifyBuy from 'shopify-buy';
import { LocalStorage, LocalStorageKeys } from './utils';

interface InitialLayout {
  cartIsOpen: boolean;
  menuIsOpen: boolean;
}

const InitialLayoutState = {
  cartIsOpen: false,
  menuIsOpen: false,
};

interface ContextShape {
  client: ShopifyBuy.Client | null;
  cart: ShopifyBuy.Cart | null;
  setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Cart | null>>;
  layout: InitialLayout;
  setLayout: React.Dispatch<React.SetStateAction<any | null>>;
}

export const StoreContext = React.createContext<ContextShape>({
  client: null,
  cart: null,
  setCart: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
  layout: InitialLayoutState,
  setLayout: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
});

export function StoreContextProvider({ children }) {
  const initialCart = LocalStorage.getInitialCart();
  const [cart, setCart] = useState<ShopifyBuy.Cart | null>(initialCart);
  const [layout, setLayout] = useState<any | null>(InitialLayoutState);

  const client = ShopifyBuy.buildClient({
    storefrontAccessToken: process.env.SHOPIFY_ACCES_TOKEN,
    domain: `${process.env.SHOPIFY_STORE}`,
  });

  useEffect(() => {
    async function getNewCart() {
      const newCart = await client.checkout.create();
      setCart(newCart);
    }

    async function refreshExistingCart(cartId: string) {
      try {
        const refreshedCart = await client.checkout.fetch(cartId);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const cartHasBeenPurchased = refreshedCart && refreshedCart.completedAt != null;
        if (cartHasBeenPurchased || cartHasBeenPurchased === null) {
          getNewCart();
        } else {
          setCart(refreshedCart);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (cart == null) {
      getNewCart();
    } else {
      refreshExistingCart(`${cart.id}`);
    }
  }, []);

  useEffect(() => {
    LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(cart));
  }, [cart]);

  return (
    <StoreContext.Provider
      value={{
        client,
        cart,
        setCart,
        layout,
        setLayout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

// --------------------------------------------------------------------------------LocaleContext

export const LocaleContext = React.createContext({ lang: '' });

// --------------------------------------------------------------------------------SettingsContext

export const SettingsContext = React.createContext({});

export function SettingsProvider(props) {
  const [purchaseAllowed, setPurchaseAllowed] = useState({
    checked: false,
    country: false,
    allowed: true,
    error: false,
  });
  const [topBarClosed, setTopBarClosed] = useState(false);
  const [renderNewsletterPopup, setrenderNewsletterPopup] = useState(false);
  const [renderHome, setRenderHome] = useState(false);
  const [renderProduct, setRenderProduct] = useState(false);
  const [renderPhilosophy, setRenderPhilosophy] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [secondMenuVisible, setSecondMenuVisible] = useState(false);
  const [secondMenuContent, setSecondMenuContent] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [renderTopBar, setRenderTopBar] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [roomFilter, setRoomFilter] = useState([]);
  const [collectionFilter, setCollectionFilter] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [productAvailabelityFilter, setProductAvailabelityFilter] = useState([]);
  const [productSorting, setProductSorting] = useState([]);
  const [materialFilter, setMaterialFilter] = useState([]);
  const [priceFilter, setPriceFilter] = useState(30000);
  const [filtersCount, setFiltersCount] = useState(0);
  const [artistFilter, setArtistFilter] = useState([]);
  const [menuShop, setMenuShop] = useState([]);
  const [allProductShopify, setAllProductShopify] = useState([]);
  const [allProductShopifyVariant, setAllProductShopifyVariant] = useState([]);
  const [allProductReview, setAllProductReview] = useState([]);
  const [maxQuantityCurrentVariant, setMaxQuantityCurrentVariant] = useState(0);
  const [quantityCurrentVariant, setQuantityCurrentVariant] = useState(0);

  useEffect(() => {
    const le = state => state.length;
    return setFiltersCount(
      le(roomFilter) +
        le(collectionFilter) +
        le(colorFilter) +
        le(productSorting) +
        le(productAvailabelityFilter) +
        (priceFilter < 30000 ? 1 : 0)
    );
  }, [roomFilter, collectionFilter, colorFilter, priceFilter, productSorting]);

  const resetFilters = () => {
    setCategoryFilter([]);
    setRoomFilter([]);
    setCollectionFilter([]);
    setColorFilter([]);
    setProductAvailabelityFilter([]);
    setProductSorting([]);
    setMaterialFilter([]);
    setArtistFilter([]);
    setPriceFilter(30000);
  };

  const values = {
    purchaseAllowed,
    setPurchaseAllowed,
    topBarClosed,
    setTopBarClosed,
    renderNewsletterPopup,
    setrenderNewsletterPopup,
    renderHome,
    setRenderHome,
    renderProduct,
    renderPhilosophy,
    setRenderPhilosophy,
    setRenderProduct,
    isCartOpen,
    setIsCartOpen,
    isCartLoading,
    setIsCartLoading,
    isMenuOpen,
    setMenuOpen,
    secondMenuVisible,
    setSecondMenuVisible,
    secondMenuContent,
    setSecondMenuContent,
    isFilterOpen,
    setFilterOpen,
    renderTopBar,
    setRenderTopBar,
    categoryFilter,
    setCategoryFilter,
    roomFilter,
    setRoomFilter,
    collectionFilter,
    setCollectionFilter,
    colorFilter,
    setColorFilter,
    materialFilter,
    setMaterialFilter,
    priceFilter,
    setPriceFilter,
    filtersCount,
    allProductReview,
    setAllProductReview,
    allProductShopify,
    allProductShopifyVariant,
    setAllProductShopifyVariant,
    setAllProductShopify,
    productAvailabelityFilter,
    setProductAvailabelityFilter,
    productSorting,
    setProductSorting,
    allLocaleSlugs: props.allLocaleSlugs,
    resetFilters,
    setArtistFilter,
    maxQuantityCurrentVariant,
    setMaxQuantityCurrentVariant,
    quantityCurrentVariant,
    setQuantityCurrentVariant,
    artistFilter,
    menuShop,
    setMenuShop,
  };

  return (
    <SettingsContext.Provider value={{ ...values }}>{props.children}</SettingsContext.Provider>
  );
}

// Shop Context

export const ShopContext = React.createContext({});
