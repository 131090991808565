/* eslint-disable */ 
import { navigate } from 'gatsby-link'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SettingsContext } from '../../contexts'
import { useCustomer,useGlobalLayoutQuery } from '../../hooks/index'
import {
  colors,
  desktopVW,
  zIndex
} from '../../styles/index'
import HeaderContentDesktop from './HeaderContentDesktop'
import HeaderContentMobile from './HeaderContentMobile'
import TopBar from './header/TopBar'



const Header = ({shopMenuHeader, pageType, navbarColor}) => {
  const layout = useGlobalLayoutQuery();
  const {
    isMenuOpen, 
    setMenuOpen,
    isCartOpen,
    setIsCartOpen,
    setCategoryFilter,
    topBarClosed,
    secondMenuVisible,
    setSecondMenuVisible,
    secondMenuContent, 
    setSecondMenuContent,
  }: {
    isMenuOpen: any
    setMenuOpen: any
    isCartOpen: any
    setIsCartOpen: any
    setCategoryFilter: any
    topBarClosed: any
    secondMenuVisible: any
    setSecondMenuVisible: any
    secondMenuContent: any 
    setSecondMenuContent: any
  } = useContext(SettingsContext)

  const isHeaderBlackOnDesktop = {
    "Home": false,
    "Text": true,
    "FindUs": true,
    "Search": true,
    "Account": true,
    "Contact": true,
    "Product": true,
    "Collection": false,
    "Philosophy": false,
    "AllProducts": true
}


  const user: null | {} = useCustomer()
  const [menuData, setMenuData] = useState<{} | {menus: [], __typename: string}>({})
  const [mobileScrolled, setMobileScrolled] = useState<boolean>(false)
  const [heroScrolled, setHeroScrolled] = useState<boolean>(false)
  const [fixedBorderOn, setFixedBorderOn] = useState<string | boolean>(false)
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false)
  const [backgroundHeader, setBackgroundHeader] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [headerColor, setHeaderColor] = useState<string>(colors.black)
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<string>("")


//-------------------------------   START HANDLERS   ------------------------------------------>

  const closeBothMenus = ():void=>{
    setMenuData(false)
    setMenuOpen(false)
    setSecondMenuVisible(false)
    setSecondMenuContent(false)
  }

  const handleMenuClick = (typeName: string, data: string | {menus: [], __typename: string}):void=>{
    if (typeName === 'ContentfulComponentMenuGroup'){
      if(typeof data === "object" && data.menus.map(({__typeName})=> __typeName === "ContentfulComponentMenu" ? true : false).includes(true)){
        setMenuData(data)
        setMenuOpen(true)
      } else {
        if(secondMenuVisible){
          setSecondMenuVisible(false)
          setSecondMenuContent("")
          setTimeout(() => {
            setMenuData(data)
            setMenuOpen(true)
          }, 250)
        } else {
          setMenuData(data)
          setMenuOpen(true)
        }
      }
    } else if (typeName === 'ContentfulComponentLink'){
      setMenuData(false)
      setMenuOpen(false)
      setFixedBorderOn(false)
      setTimeout(() => navigate(`/${data}/`), isMenuOpen ? 850 : 0)
    }
  }

  const handleMobileMenuClick = ():void=>{
    setMenuData(shopMenuHeader)
    setMenuOpen(true)
  }

  const handleBackgroundclick = ():void=>{
    if(isMenuOpen){
      setMenuOpen(false)
      setMenuData(false)
      setSecondMenuVisible(false)
      setSecondMenuContent("")
    }
  }

  const handleSubMenuClick = (data: string | undefined): void=>{
    if(data && data.charAt(0) === '%'){
      if(data.substring(1) === ""){
        setCategoryFilter([])
      } else {
        setCategoryFilter([data.substring(1)])
      }
    }
    closeBothMenus()
  }

  const handleScroll = ():void | false=> {
    const offset = window.pageYOffset 
    if(isMobile){
      if(mobileScrolled === true && offset > 10) return false
      if(offset > 1){
        setMobileScrolled(true)
      } else {
        setMobileScrolled(false)
      }
    } else {
      if(pageType === 'Home' || pageType === 'Collection' || pageType === 'Philosophy'){
        if(heroScrolled === true && offset > window.innerHeight) return
        if(offset > (((window.innerHeight)/10)*9.2)){
          setHeroScrolled(true)
        } else {
          setHeroScrolled(false)
        }
      }
      
    }
  }
  
  
  //-------------------------------   END HANDLERS   ------------------------------------------>
  //-------------------------------   START USEEFFECT   ------------------------------------------>


  
  useEffect(()=> {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isMobile, heroScrolled, pageType])
  
  useEffect(() => {
    if(user){
      setIsUserLogged(true)
    } else {
      setIsUserLogged(false)
    }
  }, [user])
  
  useEffect(() => {
    if(menuData === {} || !menuData)setFixedBorderOn(false)
  }, [menuData])
  
  useEffect(() => {
    if(pageType === 'FindUs' || pageType === 'Account' || mobileScrolled){
      setBackgroundHeader(true)
    } else {
      setBackgroundHeader(false)
    }
  }, [mobileScrolled, pageType])
  
  useEffect(()=> {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    if(window.innerWidth <= 1024){
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
        return
      }
      setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  
  useEffect(() => {
    //  this badboy manage the header colors 
    if(navbarColor) {
      setHeaderColor(navbarColor === "black" ? layout.colorMenu : layout.colorMenuInSectionHero);
      return;
    };
    isMobile
    ?
    mobileScrolled ? setHeaderColor(layout.colorMenu) : setHeaderColor(isHeaderBlackOnDesktop[pageType] ? layout.colorMenu : layout.colorMenuInSectionHero)
    :
    isMenuOpen ? setHeaderColor(layout.colorMenuHeaderWhenOpenSidebarMenu) : setHeaderColor(!isHeaderBlackOnDesktop[pageType] ? heroScrolled ? layout.colorMenu : layout.colorMenuInSectionHero : layout.colorMenuHeaderOtherThanOnTheHomePage)
    
  }, [isMobile, mobileScrolled, isMenuOpen, heroScrolled, pageType])

  useEffect(() => {
    if (pageType === "FindUs") {
      setHeaderBackgroundColor(layout.colorMenuInSectionHero);
    } else {
      setHeaderBackgroundColor("");
    }
  }, [isMobile, mobileScrolled, isMenuOpen, heroScrolled, pageType])

  //-------------------------------   END USEEFFECT   ------------------------------------------>
  //-------------------------------   START HEADER (finally)   ------------------------------------------>
  
  
  return (
    <HeaderWrapper
      headerBackgroundColor={headerBackgroundColor}
      headerColor={headerColor}
      pageType={pageType}
    >
      <TopBar />

        {
          isMobile
          ?
          <HeaderContentMobile 
              handleBackgroundclick={handleBackgroundclick}
              handleMobileMenuClick={handleMobileMenuClick}
              mobileScrolled={mobileScrolled}
              menuData={menuData}
              isUserLogged={isUserLogged}
              pageType={pageType}
              backgroundHeader={backgroundHeader}
              headerColor={headerColor}

          />
          :
          <HeaderContentDesktop 
            closeBothMenus={closeBothMenus}
            shopMenuHeader={shopMenuHeader}
            handleMenuClick={handleMenuClick}
            pageType={pageType}
            isUserLogged={isUserLogged}
            menuData={menuData}
            handleBackgroundclick={handleBackgroundclick}
            handleSubMenuClick={handleSubMenuClick}
            setFixedBorderOn={setFixedBorderOn}
            fixedBorderOn={fixedBorderOn}
            headerColor={headerColor}
          />
        }
    </HeaderWrapper>
  )
}



const HeaderWrapper = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.header};
  right: 0;
  ${({headerBackgroundColor}) => headerBackgroundColor !== "" && `
    background-color: ${headerBackgroundColor}
  ` };
  color: ${({headerColor}) => headerColor };
  ${({pageType}) => pageType === "FindUs" && `
    padding-bottom: ${desktopVW(30)};
  ` }
`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.dots};
  overflow: hidden;
  will-change: transform;
`;










export default Header
